<template>
  <div>
    <validation-provider
      name="email"
      v-slot="{ errors }"
      rules="required|email"
    >
      <b-field
        label="Email"
        :type="errors[0] && 'is-danger'"
        :message="errors[0]"
        class="input-field"
      >
        <b-input
          :id="inputId"
          :placeholder="placeholder"
          v-model="email"
          @input="emitData"
        />
      </b-field>
    </validation-provider>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      default: '',
      type: String,
    },
    inputId: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      email: '',
    }
  },

  methods: {
    emitData() {
      this.$emit('email', this.email)
    },
  },
}
</script>
